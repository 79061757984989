<template>
  <div class="not-found">
    <Navbar></Navbar>
    <div style="width: 800px;margin: 0 auto">
      <img src="../assets/img/abnormal/pic_500.svg" alt="">
      <div>
        <div style="font-size: 56px;font-weight: 500;color: rgba(0, 0, 0, 0.9);">500</div>
        <div style="font-size: 16px;font-weight: 400;color: rgba(0, 0, 0, 0.4);margin: 8px 0 24px 0">抱歉,服務器出錯了</div>
        <div @click="$router.push('/')">返回首頁</div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
export default {
  name: "Server_error",
  components:{
    Navbar
  }
}
</script>

<style scoped>
.not-found{
  margin-top: 300px;
}
.not-found>div>div,.not-found>div>img{
  display: inline-block;
  vertical-align: top;
}
.not-found>div>div>div:nth-child(3){
  width: 88px;
  height: 32px;
  background: #AB54DB;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
.not-found>div>div{
  margin-left: 143px;
  margin-top: 60px;
}
@media screen and (max-width: 900px){
  .not-found{
    margin-top: 80px;
  }
  .not-found>div>div,.not-found>div>img{
    display: block;
  }
  .not-found>div{
    width: 100% !important;
    text-align: center;
  }
  .not-found>div>img{
    width: 70%;
    margin: 0 auto;
  }
  .not-found>div>div{
    width: 100%;
    margin-left: 0;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .not-found>div>div>div{
    width: 100%;
  }
}

</style>
